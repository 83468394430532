import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

class Newsletter extends Component {

  state = {
    email: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("https://hooks.zapier.com/hooks/catch/3298056/2zxcbjq/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'newsletter',
      className: 'form',
      onSubmit: this.handleSubmit,
    }

    if (this.state.result === 'success')
      return (<p className='newsletter__success'>Thanks for subscribing!</p>)

    const buttonProps = {
      onMouseEnter: () => window?._handleMouseEnter(''),
      onMouseLeave: () => window?._handleMouseLeave(),
    }

    return (
      <form {...props}>
        <div className='form__row form__row--subscribe'>
          <input type='email' name='email' onChange={this.handleChange} placeholder='Your email' required  />
          <button type='submit' {...buttonProps}>Subscribe</button>
        </div>
      </form>
    )
  }
}

export default Newsletter
