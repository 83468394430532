import React, { Component } from 'react'
import Link from '../utils/link'
import { RightArrow, Close, Play, BackToTop } from './icons'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import Newsletter from './newsletter'
import Gif from '../assets/images/footer-icon.gif'
import GifWhite from '../assets/images/footer-icon-white.gif'

function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
function getCookie(name) {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return false
}

class Footer extends Component {

  state = {
    newsletter: false,
    reachedFooter: false,
  }

  componentDidMount() {
    this.newsPopup()
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  newsPopup = () => {
    if (getCookie('newsletter-popup')) return

    setTimeout(() => {
      this.setState({ newsletter: true }, () => {
        setCookie('newsletter-popup', 'done', 365)
      })
    }, 60000)
  }

  toggleNewsletter = () => {
    this.setState({ newsletter: !this.state.newsletter })
    setCookie('newsletter-popup', 'done', 365)
  }

  onScroll = (e) => {
    let { reachedFooter } = this.state

    const curScroll = window.scrollY || document.scrollTop
    const documentHeight = document.documentElement.scrollHeight

    const vh = window.innerHeight - 76

    // if scrolled to end of window
    if (documentHeight - curScroll - 150 <= (2 * vh)) {
      reachedFooter = true
    } else {
      reachedFooter = false
    }

    requestAnimationFrame(() => {
      this.setState({ reachedFooter })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  render() {

    const { wp } = this.props.data
    let { newsletter, reachedFooter } = this.state

    const buttonProps = {
      type: 'button',
      onMouseEnter: () => window?._handleMouseEnter('Link'),
      onMouseLeave: () => window?._handleMouseLeave(),
    }

    let footerClass = 'footer'
    if (reachedFooter) footerClass += ' footer--reached'

    return (
      <>
        <footer className={footerClass}>
          <div className='footer__inner'>
            {/* <div className='footer__contact'>
              <p>Contact our <Link to='/contact/'>Collingwood</Link> studio. <br />Follow us on <Link to='https://www.instagram.com/atollon.studio/'>Instagram</Link> and <Link to='https://www.linkedin.com/company/atollonstudio/'>LinkedIn</Link> or <button onClick={() => this.toggleNewsletter()} {...buttonProps}>subscribe</button>.</p>
            </div> */}
            <div className='footer__wrapper footer__wrapper--mobile'>
              <div className='footer__logo'>
                <Link to='/'>
                  {this.props.theme === 'light' && <img src={GifWhite} alt='Atollon' />}
                  {this.props.theme !== 'light' && <img src={Gif} alt='Atollon' />}
                </Link>
              </div>
              <div className='footer__left'>
                <ul>
                  <li><Link to='tel:1300241859'><span data-hover="1300 241 859">1300 241 859</span></Link></li>
                  <li><Link to='mailto:studio@atollon.com.au'><span data-hover="studio@atollon.com.au">studio@atollon.com.au</span></Link></li>
                </ul>
                <ul>
                  <li><Link to='https://www.instagram.com/atollon.studio/'><span data-hover="Instagram">Instagram</span></Link></li>
                  <li><Link to='https://www.linkedin.com/company/atollonstudio/'><span data-hover="LinkedIn">LinkedIn</span></Link></li>
                  <li><Link to='https://open.spotify.com/user/xcg80o81uqc2z5opeco9ntn9m/'><span data-hover="Spotify">Spotify</span></Link></li>
                  <li><Link to='https://www.behance.net/atollon/'><span data-hover="Behance">Behance</span></Link></li>
                </ul>
              </div>
              <div className='footer__right'>
                <ul>
                  <li><Link to='/about/'><span data-hover="About">About</span></Link></li>
                  <li><Link to='/projects/'><span data-hover="Projects">Projects</span></Link></li>
                  <li><Link to='/journal/'><span data-hover="Journal">Journal</span></Link></li>
                  <li><Link to='/hivemind/'><span data-hover="Hivemind">Hivemind</span></Link></li>
                </ul>
                <ul>
                  <li><Link to='/jobs/'><span data-hover="Jobs">Jobs</span></Link></li>
                  <li><Link to='/contact/'><span data-hover="Contact">Contact</span></Link></li>
                  <li><button onClick={() => this.toggleNewsletter()} {...buttonProps}><span data-hover="Subscribe">Subscribe</span></button></li>
                </ul>
                <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className='footer__back-to-top'><BackToTop color={`#fff`} /></button>
              </div>
              <p className='footer__credit'>© Atollon Collingwood Design { new Date().getFullYear() }</p>
            </div>
            <div className='footer__wrapper footer__wrapper--desktop'>
              <div className='footer__logo'>
                <Link to='/'>
                  {this.props.theme === 'light' && <img src={GifWhite} alt='Atollon' />}
                  {this.props.theme !== 'light' && <img src={Gif} alt='Atollon' />}
                </Link>
              </div>
              <div className='footer__left'>
                <ul>
                  <li><Link to='tel:1300241859'><span data-hover="1300 241 859">1300 241 859</span></Link></li>
                  <li><Link to='mailto:studio@atollon.com.au'><span data-hover="studio@atollon.com.au">studio@atollon.com.au</span></Link></li>
                </ul>
                <ul>
                  <li><Link to='/about/'><span data-hover="About">About</span></Link></li>
                  <li><Link to='/projects/'><span data-hover="Projects">Projects</span></Link></li>
                  <li><Link to='/journal/'><span data-hover="Journal">Journal</span></Link></li>
                  <li><Link to='/hivemind/'><span data-hover="Hivemind">Hivemind</span></Link></li>
                </ul>
                <ul>
                  <li><Link to='/jobs/'><span data-hover="Jobs">Jobs</span></Link></li>
                  <li><Link to='/contact/'><span data-hover="Contact">Contact</span></Link></li>
                  <li><button onClick={() => this.toggleNewsletter()} {...buttonProps}><span data-hover="Subscribe">Subscribe</span></button></li>
                </ul>
                <ul>
                  <li><Link to='https://www.instagram.com/atollon.studio/'><span data-hover="Instagram">Instagram</span></Link></li>
                  <li><Link to='https://www.linkedin.com/company/atollonstudio/'><span data-hover="LinkedIn">LinkedIn</span></Link></li>
                  <li><Link to='https://open.spotify.com/user/xcg80o81uqc2z5opeco9ntn9m/'><span data-hover="Spotify">Spotify</span></Link></li>
                  <li><Link to='https://www.behance.net/atollon/'><span data-hover="Behance">Behance</span></Link></li>
                </ul>
              </div>
              <div className='footer__right'>
                <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className='footer__back-to-top'><BackToTop color={`#fff`} /></button>
              </div>
              <p className='footer__credit'>© Atollon Collingwood Design { new Date().getFullYear() }</p>
            </div>
          </div>
        </footer>
        <div className={`overlay${newsletter ? ' active' : ''}`}>
          <div className='overlay__wrapper'>
            <button className='overlay__close' onClick={() => this.toggleNewsletter()} {...buttonProps}>
              <Close color={'#FFFFFF'} />
            </button>
            <div className='newsletter'>
              <div className='newsletter__image'>
                <GatsbyImage image={getImage(wp.siteSettings?.acf.subscribe?.image.localFile)} alt='Atollon' />
              </div>
              <div className='newsletter__content'>
                <div className='newsletter__form'>
                  <h4>{parse(wp.siteSettings?.acf.subscribe?.title)}</h4>
                  <Newsletter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer
