import React from 'react'
import PropTypes from 'prop-types'
import layoutQuery from "../hooks/use-layout-query"
import { ParallaxProvider } from 'react-scroll-parallax'
import Link from '../utils/link'

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import Cursor from '../components/cursor'

const colours = ['dark', 'cactus', 'light']

export const ThemeContext = React.createContext('light');

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  const [theme, setTheme] = React.useState('light')

  const changeTheme = () => {
    const current = colours.indexOf(theme)
    const next = current === colours.length - 1 ? 0 : current + 1
    setTheme(colours[next])
  }

  React.useEffect(() => {
    const black = '#1a1a1a'
    const cactus = '#04C100'
    const white = '#FFFFFF'
    if ( theme === 'dark' ) {
      document.documentElement.style.setProperty(`--backgroundColour`, black);
      document.documentElement.style.setProperty(`--textColour`, white);
      document.documentElement.style.setProperty(`--highlightColour`, cactus);
      document.documentElement.style.setProperty(`--rgba`, 'rgba(26, 26, 26, 0.6)');
      document.documentElement.style.setProperty(`--boxShadow`, '0px -4px 4px 0px rgba(255, 255, 255, 0.05)');
      document.documentElement.style.setProperty(`--announcementGradient`, 'linear-gradient(90.01deg, #FFFFFF 1.98%, rgba(255, 255, 255, 0) 60.11%, rgba(255, 255, 255, 0) 83.58%)');
      document.documentElement.style.setProperty(`--filter`, 'none');
    }
    if ( theme === 'cactus' ) {
      document.documentElement.style.setProperty(`--backgroundColour`, cactus);
      document.documentElement.style.setProperty(`--textColour`, black);
      document.documentElement.style.setProperty(`--highlightColour`, white);
      document.documentElement.style.setProperty(`--rgba`, 'rgba(4, 193, 0, 0.6)');
      document.documentElement.style.setProperty(`--boxShadow`, '0px -4px 4px 0px rgba(0, 0, 0, 0.05)');
      document.documentElement.style.setProperty(`--announcementGradient`, 'linear-gradient(90.01deg, #1a1a1a 1.98%, rgba(0, 0, 0, 0) 60.11%, rgba(0, 0, 0, 0) 83.58%)');
      document.documentElement.style.setProperty(`--filter`, 'invert(1)');
    }
    if ( theme === 'light' ) {
      document.documentElement.style.setProperty(`--backgroundColour`, white);
      document.documentElement.style.setProperty(`--textColour`, black);
      document.documentElement.style.setProperty(`--highlightColour`, cactus);
      document.documentElement.style.setProperty(`--rgba`, 'rgba(255, 255, 255, 0.6)');
      document.documentElement.style.setProperty(`--boxShadow`, '0px -4px 4px 0px rgba(0, 0, 0, 0.05)');
      document.documentElement.style.setProperty(`--announcementGradient`, 'linear-gradient(90.01deg, #1a1a1a 1.98%, rgba(0, 0, 0, 0) 60.11%, rgba(0, 0, 0, 0) 83.58%)');
      document.documentElement.style.setProperty(`--filter`, 'invert(1)');
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={theme}>
      <ParallaxProvider>
        <Cursor />
        <Header location={location} data={data} pageContext={pageContext} changeTheme={changeTheme} theme={theme} />
        <main>
          { children }
        </main>
        <Footer data={data} theme={theme} />
      </ParallaxProvider>
    </ThemeContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
